<button
  *ngIf="caller !== 'LOGIN_PAGE'"
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button>
  <mat-icon>close</mat-icon>
</button>

<div class="flex justify-between gap-6 sm:p-4">
  <div
    *ngIf="caller !== 'LOGIN_PAGE'"
    class="hidden sm:justify-center sm:flex-col sm:items-center md:flex md:w-1/2 border-r-1 border-light-gray">
    <div class="flex flex-wrap items-center justify-between">
      <a href="https://www.kimbocare.com/" target="_blank">
        <img
          appImg
          alt="logo"
          class="h-8 lazyload sm:h-[2.5rem]"
          src="assets/svg/logo.svg" />
      </a>
    </div>
    <div class="h-fit">
      <div
        style="margin-top: 56px"
        class="flex flex-col items-center justify-center w-11/12 gap-6 mx-auto">
        <p class="text-center">
          {{ 'sign-in.award-social-innov' | translate }}
        </p>
        <img
          appImg
          class="w-[250px] lazyload"
          src="assets/images/cover.png"
          alt="cover" />
      </div>
    </div>
  </div>
  <div class="w-full bg-white {{ caller !== 'LOGIN_PAGE' && 'md:w-6/12' }} ">
    <img
      *ngIf="
        caller !== 'LOGIN_PAGE' &&
        (formStep !== 3 || activeTotpMode !== 'creation')
      "
      appImg
      [src]="
        formStep === 1
          ? 'assets/svg/illustration-auth.svg'
          : 'assets/svg/illustration-verify.svg'
      "
      class="w-8/12 mx-auto mb-6 lazyload"
      alt="auth illustration" />

    <div class="flex items-center justify-start gap-2">
      <h5 class="text-lg font-semibold text-navy md:text-xl">
        <span *ngIf="formStep === 1">{{
          ((data && data.caller === '_SIGN_IN') || caller !== 'LOGIN_PAGE'
            ? ''
            : 'sign-up.h1'
          ) | translate
        }}</span>
        <span *ngIf="formStep === 2">{{
          'sign-in.h1-two-fact' | translate
        }}</span>
      </h5>
    </div>

    <app-google-login
      *ngIf="
        formStep === 1 &&
        utilService.getMobileOperatingSystem() !== MOBILEOS.IOS
      "
      [data]="data"
      class="block mt-5"></app-google-login>

    <div
      *ngIf="
        formStep === 1 &&
        utilService.getMobileOperatingSystem() !== MOBILEOS.IOS
      "
      class="w-full inline-flex items-center justify-center relative mx-auto mt-2">
      <hr class="w-64 h-px my-8 bg-gray-200 border-0" />
      <span
        class="absolute px-3 font-medium -translate-x-1/2 bg-white left-1/2">
        {{ 'hcp-home.or' | translate }}
      </span>
    </div>

    <div *ngIf="formStep === 2" class="flex items-start mt-6">
      <div
        class="flex-shrink-0 w-6 h-6 fill-current text-blue"
        aria-label="info icon"
        [inlineSVG]="'assets/svg/info.svg'"></div>
      <p
        class="ml-2"
        [innerHTML]="
          (isPhoneNumber
            ? 'sign-in.2fa-next-step-info-phone'
            : 'sign-in.2fa-next-step-info-email'
          ) | translate : { contact: identifier }
        "></p>
    </div>

    <form class="{{ formStep === 1 ? 'mt-0' : 'mt-8' }}">
      <div *ngIf="formStep === 1">
        <app-form-field
          *ngIf="!isPhoneNumber"
          [label]="
            (identifier.length === 0
              ? 'forms.sign_in_with_tel_or_email'
              : 'forms.email-placeholder'
            ) | translate
          "
          [error]="fromError">
          <input
            (input)="setIdentifier($event)"
            [(ngModel)]="identifier"
            [ngModelOptions]="{ standalone: true }"
            class="
     {{ input_cls.common }}
    {{ invalid ? input_cls.invalid : '' }}
   {{ disabled ? input_cls.disabled : '' }}"
            type="text"
            name="identifier"
            class="text-[14px]"
            id="tel"
            [placeholder]="'forms.phone-number-placeholder' | translate" />
        </app-form-field>

        <div *ngIf="isPhoneNumber" class="relative w-full mt-6">
          <button
            mat-button
            color="primary"
            style="position: absolute !important"
            class="-top-[8px] right-0 z-base"
            type="button"
            (click)="resetLoginForm()">
            <span class="inline-block p-1">{{
              'common.reset-label' | translate
            }}</span>
          </button>

          <app-phone-number-dropdown
            class="w-full"
            tabindex="1"
            (triggerEvent)="handlePhoneEvent($event)"
            [options]="countryListToOptionObject()"
            [tel]="_identifier"
            [withWhatapp]="false"
            [placeholder]="'forms.phone-placeholder' | translate"
            [name]="
              'kimbo-create-contact.phone-label' | translate
            "></app-phone-number-dropdown>
        </div>

        <div class="mt-8">
          <button
            (click)="sentVerificationCode()"
            class="w-full h-12"
            mat-raised-button
            color="primary"
            [disabled]="isLoading"
            type="submit">
            <app-spinner
              *ngIf="isLoading"
              class="inline-block w-6 h-6 ml-4 filter-blue"></app-spinner>
            <span *ngIf="!isLoading">{{
              'sign-in.submit-button' | translate
            }}</span>
          </button>
        </div>
      </div>
      <div *ngIf="formStep === 2">
        <app-form-field
          [label]="'sign-in.2fa-code-label' | translate"
          [error]="fromError">
          <input
            [(ngModel)]="loginModel.code"
            [ngModelOptions]="{ standalone: true }"
            class="
     {{ input_cls.common }}
    {{ invalid ? input_cls.invalid : '' }}
   {{ disabled ? input_cls.disabled : '' }}"
            type="text"
            name="authCode"
            id="authCode"
            [placeholder]="'sign-in.2fa-code-placeholder' | translate" />
        </app-form-field>

        <div class="flex flex-wrap items-center justify-center w-full mt-8">
          <p>
            <span class="text-sm">{{ 'forms.resend-code' | translate }}</span>
            <span *ngIf="!activateResendCode" class="text-sm">
              {{ 'forms.resend-code-here' | translate }}
            </span>
            <span
              *ngIf="activateResendCode"
              class="underline cursor-pointer text-sm px-1 font-medium mr-1 rounded-sm text-kimbo-blue bg-kimbo-blue bg-opacity-10"
              (click)="sentVerificationCode()">
              {{ 'forms.resend-code-action' | translate }}
            </span>
            <span
              *ngIf="counterResentTime > 0"
              class="text-sm px-1 mr-2 font-semibold rounded-sm text-kimbo-blue bg-kimbo-blue bg-opacity-10">
              {{
                _floor(counterResentTime / 60) > 0
                  ? _floor(counterResentTime / 60) +
                    'm ' +
                    (counterResentTime % 60) +
                    's'
                  : (counterResentTime % 60) + 's'
              }}
            </span>
          </p>
        </div>

        <div class="mt-8">
          <button
            (click)="check2FAOrLogin()"
            class="w-full h-12"
            mat-raised-button
            color="primary"
            [disabled]="!finishActions && !isComplete">
            <app-spinner
              *ngIf="!(finishActions || isComplete) || isLoading"
              class="inline-block w-6 h-6 ml-4 filter-blue"></app-spinner>
            <span *ngIf="finishActions || isComplete">{{
              'sign-in.submit-button' | translate
            }}</span>
          </button>
          <button
            (click)="cancel()"
            class="w-full h-12 mt-6"
            mat-button
            color="primary">
            <span>{{ 'common.cancel-button' | translate }}</span>
          </button>
        </div>
      </div>
      <div *ngIf="formStep === 3">
        <div class="flex flex-col items-center justify-between w-full">
          <div
            class="flex flex-col items-center justify-center w-full gap-4 mb-4"
            *ngIf="activeTotpMode === 'creation'">
            <div class="flex flex-row items-center justify-start w-full gap-4">
              <img
                src="assets/images/padlock.png"
                alt=""
                srcset=""
                class="w-12 h-12" />
              <div class="flex flex-col items-start">
                <span class="text-xl font-semibold"> TOTP </span>
                <span class="text-base font-normal">
                  {{ 'common.totp-config' | translate }}
                </span>
              </div>
            </div>
            <div
              class="flex flex-col items-start w-full gap-2 p-4 mx-auto mt-2 text-sm rounded-lg text-kimbo-blue bg-kimbo-blue bg-opacity-10"
              role="alert">
              <p>{{ 'common.totp-step-title' | translate }}</p>
              <p class="ml-4">
                <span class="font-semibold">1.</span
                >{{ 'common.totp-step-1' | translate }}
              </p>
              <p class="ml-4">
                <span class="font-semibold">2.</span
                >{{ 'common.totp-step-2' | translate }}
              </p>
              <p class="ml-4">
                <span class="font-semibold">3.</span
                >{{ 'common.totp-step-3' | translate }}
              </p>
            </div>
            <img [src]="totpQrCode" alt="" srcset="" class="w-48 h-48" />
          </div>
          <div class="flex flex-col items-start justify-between w-full gap-4">
            <div class="flex" *ngIf="activeTotpMode !== 'creation'">
              <div
                class="flex-shrink-0 w-6 h-6 fill-current text-blue"
                aria-label="info icon"
                [inlineSVG]="'assets/svg/info.svg'"></div>
              <p class="ml-2">
                {{ 'sign-in.2fa-application' | translate }}
              </p>
            </div>

            <app-form-field
              class="w-full"
              [label]="
                (activeTotpMode === 'creation' ? 'sign-in.2fa-label' : '')
                  | translate
              ">
              <input
                class="{{ input_cls.common }} {{
                  invalid ? input_cls.invalid : ''
                }} {{ disabled ? input_cls.disabled : '' }}"
                type="text"
                name="twoAuthCode"
                id="twoAuthCode"
                [(ngModel)]="twoFaCode"
                placeholder="XXXXXX"
                [disabled]="isLoading"
                (input)="verify()" />
            </app-form-field>

            <button
              (click)="loginWithVerificationCode()"
              mat-raised-button
              color="primary"
              class="w-full h-12 mt-2 rounded-sm"
              [disabled]="!twoFaCode || twoFaCode.length < 5 || isLoading">
              <app-spinner
                *ngIf="isLoading"
                class="inline-block w-6 h-6 ml-4 filter-blue"></app-spinner>
              <span *ngIf="!isLoading">
                {{ 'hcp-common.verify' | translate }}
              </span>
            </button>
            <button
              (click)="cancel()"
              class="w-full h-12 mt-2"
              mat-button
              color="primary">
              <span>{{ 'common.cancel-button' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
      <label class="ohnohoney" for="birthdate"></label>
      <input
        class="ohnohoney"
        autocomplete="off"
        type="date"
        id="birthdate"
        name="birthdate"
        [(ngModel)]="hpdate"
        placeholder="Your name birthdate" />
    </form>
  </div>
</div>
