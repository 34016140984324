import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ILoginResponse, IUser, Login } from 'src/app/core/models/models';
import {
  IVerificationResponse,
  VerificationCode,
} from 'src/app/core/models/VerificationCode';
import {
  RestAuthentificationService,
  UseCaseRoleService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { SessionService } from 'src/app/core/services/session.service';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import {
  isValidEmailAdress,
  isValidPhoneNumber,
} from 'src/app/core/utils/core';
import { ServicesService } from 'src/app/core/services/services.service';
import { Subscription } from 'rxjs';
import {
  Country,
  LoginPostActionParams,
  OptionObject,
  Phone,
} from 'src/app/core/utils/type';
import { getCountries } from 'src/app/core/utils/country';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthentificationService } from 'src/app/core/services/authentication.service';
import { MOBILEOS, PostLoginAtions } from 'src/app/core/enums/enums';
import { PreLoginInfosComponent } from './pre-login-infos/pre-login-infos.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() closeModal = new EventEmitter<void>();
  @Input() caller: 'LOGIN_PAGE' | 'OTHER' = 'OTHER';
  @Input() data!: {
    action?: PostLoginAtions;
    params?: LoginPostActionParams;
    invitation?: {
      key: string | null;
      mode: string | null;
      identifier: string | null;
      invitation_key?: string | null;
    };
    caller: '_SIGN_UP' | '_SIGN_IN';
  };
  @Input() addData!: {
    key: string | null;
    mode: string | null;
    identifier: string | null;
    invitation_key?: string | null;
  };

  input_cls = INPUT_CLASSES;
  invalid = false;
  disabled = false;
  formStep = 1;
  activeTotpMode: 'verification' | 'creation' | undefined = undefined;
  totpQrCode: string | undefined;
  twoFaCode = '';
  isLoading = false;
  fromError!: string;
  error?: string;
  isError = false;
  isSuccess = false;
  identifier = '';
  _identifier = '';
  loginModel: Login = new Login('');
  finishActions = false;
  isComplete = true;
  $rolechane!: Subscription;
  country = '';
  isPhoneNumber = false;
  phoneModel: Phone = {};
  user!: IUser;
  currentLocation?: string;
  // recaptcha
  recentToken = '';
  recaptchaAvailable = false;
  hpdate!: string;
  siteKey = environment.recaptcha.siteKey;
  countSms = 1;
  activateResendCode = false;
  $resendCodeTimeOut: any;
  $resendCodeInterval: any;
  counterResentTime = 0;
  MOBILEOS = MOBILEOS;

  constructor(
    public navigationService: CustomNavigationService,
    public authService: RestAuthentificationService,
    public userService: UserService,
    public useCaseRole: UseCaseRoleService,
    public services: ServicesService,
    public sessionService: SessionService,
    public translateService: TranslateService,
    private notificationService: NotificationService,
    public authUtilService: AuthentificationService,
    public route: Router,
    public utilService: UtilsService
  ) {}

  ngOnInit(): void {
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    if (this.caller === 'LOGIN_PAGE') {
      this.data = {
        action: undefined,
        params: undefined,
        invitation: this.addData,
        caller: '_SIGN_IN',
      };
      if (this.data.invitation && !this.data.invitation.invitation_key) {
        this.identifier = this.data.invitation.identifier!;
        if (isValidPhoneNumber(this.identifier)) {
          this._identifier = this.identifier;
          this.isPhoneNumber = true;
        }
      }
    }

    this.country = this.sessionService.getCountry().country;
    if (this.data?.params?.identifier || this.data?.invitation?.key) {
      this.identifier = this.data.params?.identifier
        ? this.data.params?.identifier
        : this.data.invitation?.identifier!;
      if (isValidPhoneNumber(this.identifier)) {
        this._identifier = this.identifier;
        this.isPhoneNumber = true;
      }
    }

    this.currentLocation = this.route.url;
    this.$rolechane = this.services.eventService.subscribe(
      'change:role',
      () => {
        this.finishActions = true;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.$rolechane) this.$rolechane.unsubscribe();
    if (this.$resendCodeTimeOut) {
      clearTimeout(this.$resendCodeTimeOut);
    }

    if (this.$resendCodeInterval) {
      clearInterval(this.$resendCodeInterval);
    }
  }

  setIdentifier(event: any): void {
    this.identifier = event.target.value;
    this._identifier = event.target.value;
    if (this._identifier.trim().length > 0) {
      const first = this._identifier[0];
      if (/^[0-9]|\+$/.test(first)) {
        this.isPhoneNumber = true;
      }
    }
  }

  resetLoginForm(): void {
    this.isPhoneNumber = false;
    this.identifier = '';
    this.error = undefined;
  }

  openSignUpModal(): void {
    this.closeModal.emit();
    this.services.sessionService.storeRequestToLogin();
    this.services.modalService
      .openModal(PreLoginInfosComponent, {
        width: '400px',
        height: 'auto',
        data: {
          caller: '_SIGN_UP',
        },
      })
      .subscribe(res => {
        if (
          res === '_SIGN_IN' ||
          (typeof res === 'object' && res.caller === '_SIGN_IN')
        ) {
          this.services.modalService
            .openModal(LoginModalComponent, {
              width: '950px',
              height: 'auto',
              data: {
                params: {
                  ...(typeof res === 'object' && { identifier: res.state }),
                },
                caller: '_SIGN_IN',
              },
            })
            .subscribe(() =>
              this.services.sessionService.deleteRequestToLogin()
            );
        } else {
          this.services.sessionService.deleteRequestToLogin();
        }
      });
  }

  handlePhoneEvent(phone: Phone): void {
    this.phoneModel = phone;
    this.identifier = this.phoneModel.phone!;
  }

  public sentVerificationCode(): void {
    if (!this.identifier) {
      this.fromError = this.translateService.instant('errors.required');
      return;
    }
    if (
      !isValidPhoneNumber(this.identifier) &&
      !isValidEmailAdress(this.identifier)
    ) {
      const first = this.identifier[0];
      if (/^[0-9]|\+$/.test(first)) {
        this.fromError = this.translateService.instant(
          'errors.invalid-phone-number'
        );
      } else {
        this.fromError = this.translateService.instant('errors.invalid-email');
      }

      return;
    }

    const language_id = String(
      this.translateService.currentLang === 'fr' ? 1 : 2
    );
    const identifierModel = new VerificationCode(
      this.hpdate ?? null,
      this.identifier,
      language_id,
      this.sessionService.getUser()?.id
    );

    this.fromError = '';
    this.isLoading = true;
    this.authUtilService.sentVerificationCode(identifierModel).subscribe({
      next: (res: IVerificationResponse) => {
        this._identifier = this.identifier;
        this.formStep = 2;
        this.activeTotpMode = res.twofa_mode;
        this.totpQrCode = res.qr_code;
        if (this.$resendCodeTimeOut) {
          clearTimeout(this.$resendCodeTimeOut);
        }
        if (this.$resendCodeInterval) {
          clearInterval(this.$resendCodeInterval);
        }

        this.activateResendCode = false;
        const time = this.countSms * 30000;
        this.counterResentTime = time / 1000;
        this.$resendCodeTimeOut = setTimeout(() => {
          this.activateResendCode = true;
        }, time);

        this.$resendCodeInterval = setInterval(() => {
          this.counterResentTime--;
          if (this.counterResentTime == 0) {
            clearInterval(this.$resendCodeInterval);
          }
        }, 1000);
        this.countSms += 10;
        this.services.eventService.publish('hide:loading');
        this.error = '';
      },
      error: () => {
        this.error = this.translateService.instant('errors.generic');
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  public loginWithVerificationCode(): void {
    if (!this.loginModel.code) {
      this.fromError = this.translateService.instant('errors.required');
      return;
    }
    this.loginModel.identifiant = this.identifier;
    this.loginModel.date = this.hpdate ?? null;

    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(c => {
      if (c.code.toLocaleLowerCase() === this.country.toLocaleLowerCase()) {
        this.loginModel.country = c.name;
      }
    });

    if (this.data?.invitation?.key && this.caller === 'OTHER')
      this.services.sessionService.clearStorage(false);

    if (this.data?.invitation?.key) {
      this.loginModel.key = this.data?.invitation?.key;
    }

    if (this.data?.invitation?.mode) {
      this.loginModel.mode = this.data?.invitation?.mode;
    }

    if (this.activeTotpMode) {
      this.loginModel.totp_code = this.twoFaCode;
    }

    this.isLoading = true;
    this.services.eventService.publish('show:loading');
    this.authUtilService
      .loginWithVerificationCode(this.loginModel, this.data?.invitation?.key)
      .subscribe({
        next: (res: ILoginResponse) => {
          this.isComplete = true;
          if (res.status_code !== 401) {
            this.user = res.user;
            this.finishActions = true;
            this.isLoading = false;
            this.userService.view(res.user_id).subscribe({
              next: (userRes: IUser) => {
                this.sessionService.deleteUser();
                this.sessionService.storeUser(userRes);
              },
              error: () => {
                this.error = this.translateService.instant('errors.generic');
                // console.log(this.error);
              },
            });
          }

          if (this.data.invitation?.invitation_key) {
            this.useCaseRole
              .addPublicMember(this.data.invitation?.invitation_key)
              .subscribe({
                next: (res: any) => {
                  this.notificationService.success(
                    this.translateService.instant('common.add-pending-member')
                  );
                },
                error: (err: any) => {
                  this.notificationService.danger(
                    this.services.translateService.instant(
                      err.translate ?? 'errors.generic'
                    )
                  );
                },
                complete: () => {},
              });
          }

          if (res.status_code !== 401 && this.caller === 'OTHER') {
            this.services.eventService.publish('close:dialog');
          }
        },
        error: err => {
          this.error = this.translateService.instant(
            err.translate ?? 'errors.generic'
          );
          this.services.eventService.publish('hide:loading');
          this.notificationService.danger(this.error!);
          this.isComplete = true;
          this.finishActions = true;
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  _floor(number: number) {
    return Math.floor(number);
  }

  check2FAOrLogin() {
    if (this.activeTotpMode && this.formStep != 3) {
      this.formStep = 3;
    } else {
      this.loginWithVerificationCode();
    }
  }

  verify() {
    if (this.twoFaCode && this.twoFaCode.length === 6) {
      this.loginWithVerificationCode();
    }
  }

  countryListToOptionObject(): OptionObject[] {
    const countryOptions: OptionObject[] = [];
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(country => {
      countryOptions.push({
        label: country.code,
        value: country.name,
      });
    });
    return countryOptions;
  }

  cancel(): void {
    if (this.formStep === 2) {
      this.formStep = 1;
    } else if (this.formStep === 3) {
      this.formStep = 2;
    }
  }

  cleanStorage() {
    this.sessionService.deleteAccessToken();
    this.sessionService.deleteCurrentRole();
    this.sessionService.deleteCurrencie();
    this.sessionService.deleteUserCurrencie();
    this.sessionService.deleteHcpCurrencie();
  }

  addUniqueIdToElements() {
    const elements = document.querySelectorAll('app-root *');
    let id = 0;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].hasAttribute('id')) {
        elements[i].setAttribute('id', 'kimbo-unique-id-' + id);
        id++;
      }
    }
  }
}
